$weights: (
        400: 'Regular',
        500: 'Medium',
        700: 'Bold',
);
$styles: (normal: '', italic: 'Italic');

@each $family in 'DMSans' {
  @each $weight, $fileWeight in $weights {
    @each $style, $fileStyle in $styles {
      @font-face {
        font-family: $family;
        font-style: $style;
        font-weight: $weight;
        font-display: swap;
        src: url('./#{$family}-#{$fileWeight}#{$fileStyle}.ttf') format('truetype');
      }
    }
  }
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./Inter-Medium.ttf') format('truetype');
}
