@tailwind base;
@tailwind components;
@tailwind utilities;

@import "public/fonts/fonts";

// prevent browser-specific tap highlights on e.g. all buttons
// (so that they do not shortly turn blue when being clicked)
// see https://github.com/tailwindlabs/tailwindcss/pull/9050#issuecomment-1208468878
@layer base {
  html {
    -webkit-tap-highlight-color: transparent !important;
  }
}

html {
  @apply bg-n200LightGrey h-full antialiased overflow-y-scroll;
}

#root {
  @apply min-h-full h-full w-full;
}

body {
  @apply h-full text-base text-n600DarkBlue;
}

h1 {
  @apply text-2xl font-bold leading-8
}

h2 {
  @apply text-base text-n500DarkGrey font-medium
}

.hyphens-none {
  hyphens: none;
}

.hyphens-manual {
  hyphens: manual;
}

.hyphens-auto {
  hyphens: auto;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
